var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center align-items-md-center"},[_c('div',{staticClass:"col-md-11"},[_c('div',{staticClass:"card full-height bg-ligth"},[_vm._m(0),_c('div',{staticClass:"card-body table-responsive table-responsive"},[_c('b-table',{staticClass:"table table-head-custom table-vertical-center table-head-bg table-borderless text-uppercase mt-3",attrs:{"fields":[
            'usuario',
            'cpf',
            'tipo_carteirinha',
            'status',
            'created_at',
            'hora',
            'acoes',
          ],"items":_vm.lista_beneficiados,"per-page":_vm.perPage,"current-page":_vm.currentPage,"id":"perfil-table","show-empty":"","empty-text":"Nenhum registro encontrado!"},scopedSlots:_vm._u([{key:"head(acoes)",fn:function(){return [_c('span')]},proxy:true},{key:"head(created_at)",fn:function(){return [_c('span',[_vm._v("Data inclusão")])]},proxy:true},{key:"cell(created_at)",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("formatEnToBr")(item.created_at)))])]}},{key:"cell(hora)",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("horaEnToBr")(item.created_at)))])]}},{key:"cell(usuario)",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.usuario.nome_sobrenome))])]}},{key:"cell(cpf)",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.usuario.cpf))])]}},{key:"cell(status)",fn:function({ item }){return [_c('span',{staticClass:"label label-lg label-inline px-2 pb-2 pt-3",class:{
                'label-light-success': item.status === 1,
                'label-light-warning': item.status === 2,
                'label-light-danger': item.status === 3,
              }},[_vm._v(" "+_vm._s(item.status == 1 ? "Válida" : item.status == 2 ? "aguardando" : "recusada")+" ")])]}},{key:"cell(tipo_carteirinha)",fn:function({ item }){return [_c('span',{staticClass:"label label-lg label-inline px-2 pb-2 pt-3"},[_vm._v(" "+_vm._s(_vm.tipo_bene[item.tipo_beneficiado_id])+" ")])]}},{key:"cell(acoes)",fn:function({ item }){return [_c('div',{staticClass:"text-right w-100"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.lista_permissoes_filial.u_Usuario),expression:"lista_permissoes_filial.u_Usuario"},{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-icon btn-light btn-sm mx-1",attrs:{"title":"Olhar registro"},on:{"click":function($event){return _vm.showModalBene(item)}}},[_c('i',{staticClass:"far fa-eye text-info"})])])]}}])}),_c('b-pagination',{attrs:{"total-rows":_vm.lista_beneficiados.length,"per-page":_vm.perPage,"aria-controls":"perfil-table"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"fullscreen":"","scrim":false,"transition":"dialog-bottom-transition"},model:{value:(_vm.modalBeni),callback:function ($$v) {_vm.modalBeni=$$v},expression:"modalBeni"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.modalBeni = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Fechar")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"variant":"text"},on:{"click":_vm.aprovar}},[_vm._v(" Confirmar ")]),_c('v-btn',{attrs:{"variant":"text"},on:{"click":_vm.recusar}},[_vm._v(" Recusar ")])],1)],1),_c('v-card',{staticStyle:{"height":"100%","width":"100%"}},[_c('iframe',{attrs:{"src":_vm.host + _vm.arquivo_id + '?token=' + _vm.token,"frameborder":"0","height":"700px","width":"100%"}})])],1)],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Beneficiado")])])
}]

export { render, staticRenderFns }