<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-11">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">Beneficiado</h3>
        </div>

        <div class="card-body table-responsive  table-responsive">
          <b-table
            :fields="[
              'usuario',
              'cpf',
              'tipo_carteirinha',
              'status',
              'created_at',
              'hora',
              'acoes',
            ]"
            :items="lista_beneficiados"
            :per-page="perPage"
            :current-page="currentPage"
            id="perfil-table"
            class="table table-head-custom  table-vertical-center table-head-bg table-borderless text-uppercase mt-3"
            show-empty
            empty-text="Nenhum registro encontrado!"
          >
            <template #head(acoes)><span></span></template>
            <template #head(created_at)><span>Data inclusão</span></template>
            <template #cell(created_at)="{ item }"
              ><span>{{ item.created_at | formatEnToBr }}</span></template
            >
            <template #cell(hora)="{ item }"
              ><span>{{ item.created_at | horaEnToBr }}</span></template
            >
            <template #cell(usuario)="{ item }"
              ><span>{{ item.usuario.nome_sobrenome }}</span></template
            >
            <template #cell(cpf)="{ item }"
              ><span>{{ item.usuario.cpf }}</span></template
            >
            <template #cell(status)="{ item }">
              <span
                class="label label-lg label-inline px-2 pb-2 pt-3"
                :class="{
                  'label-light-success': item.status === 1,
                  'label-light-warning': item.status === 2,
                  'label-light-danger': item.status === 3,
                }"
              >
                {{
                  item.status == 1
                    ? "Válida"
                    : item.status == 2
                    ? "aguardando"
                    : "recusada"
                }}
              </span></template
            >

            <template #cell(tipo_carteirinha)="{ item }">
              <span class="label label-lg label-inline px-2 pb-2 pt-3">
                {{ tipo_bene[item.tipo_beneficiado_id] }}
              </span></template
            >
            <template #cell(acoes)="{ item }">
              <div class="text-right w-100">
                <button
                  v-show="lista_permissoes_filial.u_Usuario"
                  @click="showModalBene(item)"
                  class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover
                  title="Olhar registro"
                >
                  <i class="far fa-eye text-info"></i>
                </button>

                <!-- <button
                  v-show="lista_permissoes_filial.lock_Usuario"
                  @click="confirm(item)"
                  class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover
                  title="Bloquear/Desbloquear registro"
                >
                  <i class="fas fa-trash text-danger"></i>
                </button> -->

                <!-- <button
                  v-show="lista_permissoes_filial.u_Beneficiado"
                  @click="showModal(item)"
                  class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover
                  title="Bloquear/Desbloquear registro"
                >
                  <i class="fas fa-plus text-success"></i>
                </button> -->
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="lista_beneficiados.length"
            :per-page="perPage"
            aria-controls="perfil-table"
          >
          </b-pagination>
        </div>
      </div>
    </div>
    <!-- <v-dialog v-model="modal_fatura" max-width="900" persistent>
      <template>
        <v-card>
          <v-card-title>Novo Lote</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div class="form-group row">
              <div class="col-md-3">
                <label class="col-md-12">Titulo:*</label>
                <input
                  disabled
                  type="text"
                  class="form-control"
                  v-model="form.tipo"
                  placeholder="Digite o tipo do Beneficiado"
                />
              </div>
              <div class="col-md-3">
                <label class="col-md-12">Tipo:*</label>
                <input
                  disabled
                  type="text"
                  class="form-control"
                  v-model="form.tipo2"
                  placeholder="Digite o tipo do Beneficiado"
                />
              </div>
              <div class="col-md-3">
                <label class="col-md-12">Lote:*</label>
                <input
                  disabled
                  type="text"
                  class="form-control"
                  v-model="form.lote"
                  placeholder="Digite o lote do Beneficiado"
                />
              </div>
              <div class="col-md-3">
                <label class="col-md-12">Obs:*</label>
                <input
                  disabled
                  type="text"
                  class="form-control"
                  v-model="form.obs"
                  placeholder="Digite o lote do Beneficiado"
                />
              </div>

              <div class="col-md-3">
                <label class="col-md-12">Valor:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="form.valor"
                  placeholder="Digite o Valor do Beneficiado"
                  @input="valorFormat"
                  v-mask="variableWithMask"
                />
              </div>
              <div class="col-md-6 pt-1">
                <label class="col-md-12">Quantidade lote:</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="form.quantidade_lote"
                  placeholder="Quantidade de Beneficiado do lote"
                />
              </div>
              <div class="row form-group col-md-12 pt-2">
              <div class="col-md-10 pull-left">
                <div class="md-radio-inline text-uppercase">
                  <label>Status:*</label>
                  <div class="space d-flex">
                    <b-form-radio
                      :inline="true"
                      value="2"
                      v-model="form.status"
                    >
                      inativo
                    </b-form-radio>
                    <b-form-radio
                      :inline="true"
                      value="1"
                      v-model="form.status"
                    >
                      ativo
                    </b-form-radio>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="modal_fatura = false">
              Cancelar
            </v-btn>
            <v-btn color="blue darken-1" text @click="submitNovoLote">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog> -->
    <v-row justify="center">
    <v-dialog
      v-model="modalBeni"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
    >
    
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="modalBeni = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Fechar</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              variant="text"
              @click="aprovar"
            >
              Confirmar
            </v-btn>
             <v-btn
              variant="text"
              @click="recusar"
            >
              Recusar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card style="height:100%;width:100%;">
        <iframe :src="host + arquivo_id + '?token=' + token" frameborder="0"  height="700px" width="100%"></iframe>

      </v-card>
   
      </v-card>
   
    </v-dialog>
  </v-row>
  
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import dateFormat from "@/core/mixins/dateFormat";
import EventoService from "@/core/services/evento.service";
import moneyFormat from "@/core/mixins/moneyFormat";
import JwtService from "@/core/services/jwt.service";

export default {
  mixins: [fireAlert, dateFormat, moneyFormat],
  data() {
    return {
      modalBeni: false,
      tipo_bene: {
        1: "Estudante",
        2: "Idoso",
        3: "Professor",
        4: "Jovens de baixa renda ",
        5: "Atletica universitaria",
      },
      currentPage: 1,
      perPage: 10,
      eventos_id: EventoService.getEventoId(),
      modal_fatura: false,
      fatura: null,
      beneficiado: null,
      arquivo_id: null,

      variableWithMask: "",
      host: process.env.VUE_APP_API_URL + "arquivo/doc/",
      token: JwtService.getToken(),
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Beneficiados" }]);
  },

  created() {
    this.listar_beneficiados();
  },
  computed: {
    lista_beneficiados() {
      return this.$store.state.beneficiado.lista_beneficiados;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.beneficiado.mensagem_alert;
    },
  },
  methods: {
    showModalBene(value) {
      this.arquivo_id = value.arquivo_id;
      this.beneficiado = value;
      this.modalBeni = true;
    },
    async listar_beneficiados() {
      await this.$store.dispatch(
        "beneficiado/listar_beneficiados"
      );

      // this.eventos_id =  this.$route.params.eventos_id
    },
  async  aprovar() {
  
    await  this.$store.dispatch("beneficiado/update_beneficiado",{id:this.beneficiado.id,status:1});
      this.$store.dispatch(
        "beneficiado/listar_beneficiados"
      );
      this.modalBeni = false
      this.fireAlert({
        ...this.mensagem_alert
      });
    },
 async   recusar() {
  
 await this.$store.dispatch("beneficiado/update_beneficiado",{id:this.beneficiado.id,status:3});
  this.$store.dispatch(
        "beneficiado/listar_beneficiados"
      );
      this.modalBeni = false
      this.fireAlert({
        ...this.mensagem_alert
      });

},

 
    async bloquear(value) {
      await this.$store.dispatch("beneficiados/bloquear_fatura", value);
      this.fireAlert({
        ...this.mensagem_alert,
      });
    },
    showModal(item) {
      this.form = {
        obs2: item.obs2,

        desc1: item.desc1,
        desc2: item.desc2,
        desc3: item.desc3,
        cor: item.cor,
        status: item.status,

        eventos_id: item.eventos_id,
        setor_id: item.setor_id,

        tipo: item.tipo,
        tipo2: item.tipo2,

        obs: 1 + parseInt(item.obs.replace(/[^0-9]/g, "")) + "° LOTE",
        quantidade_lote: item.quantidade_lote,
        lote: parseInt(item.lote) + 1,
        valor: null,
      };
      this.fatura = item;
      this.modal_fatura = true;
    },

    async preenxerCampos() {
      EventoService.setEventoId(this.eventos_id);
      await this.$store.dispatch(
        "beneficiados/listar_beneficiados",
        EventoService.getEventoId()
      );

      // this.fireAlert({
      //   tipo: "success",
      //   msg: "Selecionado Evento Global",
      // });
    },
    valorFormat() {
      let pa = "";

      for (let i = 0; i < this.form.valor.length; i++) {
        if (i > 5) {
          pa = pa + "#";
        }
      }

      this.variableWithMask = "R$" + pa + "#,##";
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform .2s ease-in-out;
}
</style>